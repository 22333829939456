export const useCurrentLocation = () => {
  const _currentLocationId = usePersistState<number | null>('current-location-id', null)

  const currentLocationId = computed<number | null>({
    get: () => _currentLocationId.value,
    set: (value) => (_currentLocationId.value = value),
  })

  const recoverLocationId = async (userLocationId: number | null): Promise<void> => {
    const { getLocations } = useLocation()
    const locations = await getLocations()

    // userLocationが存在する（見える）ことを確認する
    // 通常のデータなら必ずlocationは見つかるはず
    if (userLocationId && locations.some((location) => location.id === userLocationId)) {
      currentLocationId.value = userLocationId
      return
    }
    currentLocationId.value = locations.length > 0 ? locations[0].id : null
  }

  return {
    currentLocationId,
    recoverLocationId,
  }
}
