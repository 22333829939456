<template>
  <div class="loading-icon">
    <img data-test="loading-image" src="~/assets/images/icons/loading.gif" />
  </div>
</template>

<style scoped lang="scss">
.loading-icon {
  width: 64px;
  height: 64px;
}
</style>
