export const usePersistState = <T>(key: string, initialValue: T): Ref<T> => {
  const defaultValue = initialValue
  const get = (key: string) => {
    try {
      return JSON.parse(localStorage.getItem(key) + '')
    } catch {
      return null
    }
  }
  const storedValue = get(key)
  const set = (key: string, value: T) => {
    localStorage.setItem(key, JSON.stringify(value))
  }

  if (storedValue === null) {
    set(key, defaultValue)
  }
  const refs: Ref<T> = useState<T>(key, () => (storedValue ?? defaultValue) as T)
  watch(refs, (newValue) => set(key, newValue))
  return refs
}
