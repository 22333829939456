import type { Organization } from '~/entities/organization'
import { getOrganizationByAccountId, updateOrganization } from '~/repositories/organizationRepository'

const recoverOrganization = (organization: Ref<Organization | null>) => async () => {
  const supabaseUser = useSupabaseUser()
  if (!organization.value && supabaseUser.value) {
    const data = await getOrganizationByAccountId(supabaseUser.value.id)
    organization.value = data
  }
}

export const useOrganization = () => {
  const organization = useState<Organization | null>('currentOrganization')
  return {
    organization,
    updateOrganization,
    recoverOrganization: recoverOrganization(organization),
  }
}
