import { useI18n } from 'vue-i18n'
import type { User } from '~/entities/user'
import { isUserAccountAlreadyInUse } from '~/repositories/userAccountRepository'

export const MAX_NAME_LENGTH = 15
const MAX_NAME_PRONUNCIATION_LENGTH = 15
const MAX_EMAIL_LENGTH = 255

export const useUserValidator = (
  name: Ref<string>,
  namePronunciation: Ref<string>,
  email: Ref<string>,
  role?: Ref<User['role']>,
  locationId?: Ref<User['location_id'] | null>
) => {
  const { t } = useI18n()
  const { currentLocale } = useLocale()

  const nameErrorMessage = computed(() => {
    if (!name.value) return t('fieldが入力されていません。', { field: t('名前') })
    if (get2byteLength(name.value) > MAX_NAME_LENGTH * 2) {
      return t('fieldは全角count文字以下で設定してください。', {
        field: t('名前'),
        count: currentLocale.value === 'ja' ? MAX_NAME_LENGTH : MAX_NAME_LENGTH * 2,
      })
    }
    return ''
  })

  const namePronunciationErrorMessage = computed(() => {
    if (get2byteLength(namePronunciation.value) > MAX_NAME_PRONUNCIATION_LENGTH * 2) {
      return t('fieldは全角count文字以下で設定してください。', {
        field: t('読み方'),
        count: currentLocale.value === 'ja' ? MAX_NAME_PRONUNCIATION_LENGTH : MAX_NAME_PRONUNCIATION_LENGTH * 2,
      })
    }
    return ''
  })

  const emailErrorMessage = ref('')
  watch([email], () => {
    if (email.value === '') {
      emailErrorMessage.value = t('fieldが入力されていません。', { field: t('メールアドレス') })
      return
    }
    if (!isValidEmail(email.value)) {
      emailErrorMessage.value = t('不正なメールアドレスが指定されています。')
      return
    }
    if (get2byteLength(email.value) > MAX_EMAIL_LENGTH) {
      emailErrorMessage.value = t('fieldはcount文字以下で設定してください。', {
        field: t('メールアドレス'),
        count: MAX_EMAIL_LENGTH,
      })
      return
    }
    emailErrorMessage.value = ''
  })

  const validateDuplicateEmail = async () => {
    const exists = await isUserAccountAlreadyInUse([email.value])
    if (exists) {
      emailErrorMessage.value = t('入力されたメールアドレスは既に使用されています。')
    }
  }

  const locationErrorMessage = computed(() => {
    if (!role || !locationId) return ''
    if (role.value === 'ORGANIZATION_ADMIN') return ''
    if (!locationId.value) {
      return t('拠点が選択されていません。')
    }
    return ''
  })

  const isValid = computed(() => {
    return (
      !nameErrorMessage.value &&
      !namePronunciationErrorMessage.value &&
      !emailErrorMessage.value &&
      !locationErrorMessage.value
    )
  })

  return {
    nameErrorMessage,
    namePronunciationErrorMessage,
    emailErrorMessage,
    locationErrorMessage,
    validateDuplicateEmail,
    isValid,
  }
}
