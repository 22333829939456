import { LimitExceededError } from './error'
import type { Database } from '~/apis/generated/supabase'
import type { LocationUpdateParams } from '~/entities/location'

const MAX_LOCATIONS = 50

export const getLocations = async () => {
  const { data, error } = await useSupabaseClient<Database>()
    .from('locations')
    .select('*')
    .order('id', { ascending: true })
  if (error) {
    throw error
  }
  return data
}

export const getLocation = async (locationId: number) => {
  const { data, error } = await useSupabaseClient<Database>()
    .from('locations')
    .select('*')
    .eq('id', locationId)
    .single()

  if (error) {
    throw error
  }
  return data
}

export const getLocationsCount = async (): Promise<number> => {
  const { count, error } = await useSupabaseClient<Database>()
    .from('locations')
    .select('id', { count: 'exact', head: true })
  if (error) throw error
  return count ?? 0
}

export const createLocation = async (name: string): Promise<void> => {
  const { organization } = useOrganization()
  if (!organization.value) throw new Error('Organization is not set')
  const count = await getLocationsCount()
  if (count >= MAX_LOCATIONS) throw new LimitExceededError()
  const { error } = await useSupabaseClient().from('locations').insert({ name, organization_id: organization.value.id })

  if (error) {
    throw error
  }
}

export const updateLocation = async (id: number, params: LocationUpdateParams) => {
  const { data, error } = await useSupabaseClient<Database>()
    .from('locations')
    .update(params)
    .eq('id', id)
    .select()
    .single()
  if (error) {
    throw error
  }
  return data
}

export const deleteLocation = async (id: number): Promise<void> => {
  const { error } = await useSupabaseClient().from('locations').delete().eq('id', id)
  if (error) {
    throw error
  }
}
