<script setup lang="ts">
import type { RouteLocationNamedRaw } from 'vue-router'
import type { AnchorHTMLAttributes } from 'vue'

type Color = keyof typeof TEXT_BUTTON_COLOR

type Props = {
  to?: RouteLocationNamedRaw
  href?: string
  target?: AnchorHTMLAttributes['target']
  color?: Color
}

const props = withDefaults(defineProps<Props>(), {
  color: 'none',
  to: undefined,
  href: undefined,
  target: undefined,
})

const color = computed(() => TEXT_BUTTON_COLOR[props.color].COLOR)
const textDecoration = computed(() => (props.color === 'none' ? 'underline' : 'none'))
</script>

<template>
  <div class="text-link-wrapper">
    <template v-if="to">
      <NuxtLink :to="to" :target="target" class="text-link"><slot /></NuxtLink>
    </template>
    <template v-else-if="href">
      <NuxtLink :to="href" :target="target" class="text-link" external> <slot /></NuxtLink>
    </template>
  </div>
</template>

<style lang="scss" scoped>
.text-link-wrapper {
  display: flex;
  align-items: center;
}

.text-link {
  color: v-bind(color);
  text-decoration: v-bind(textDecoration);
  font-weight: bold;

  &:hover {
    opacity: 0.7;
  }
}
</style>
