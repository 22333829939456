import VueGtag, { type PluginOptions } from 'vue-gtag'
import type { RouteLocationNormalized } from 'vue-router'

// 現状は日本語のみのため強制的にja設定
export default defineNuxtPlugin(({ vueApp }) => {
  const router = useRouter()

  const {
    public: {
      analytics: { measurementId },
    },
  } = useRuntimeConfig()

  if (!measurementId) return

  const options = {
    appName: 'Checkup!admin',
    config: { id: measurementId },
    pageTrackerScreenviewEnabled: true,
    pageTrackerTemplate: (to: RouteLocationNormalized) => {
      return {
        page_title: to.name,
        page_path: to.path,
      }
    },
  } as PluginOptions

  vueApp.use(VueGtag, options, router)
})
