<script setup lang="ts">
import { locale } from 'dayjs'
import { useI18n } from 'vue-i18n'

const siteTitle = 'Checkup!'

// https://nuxt.com/docs/getting-started/seo-meta#title-template
useHead({
  titleTemplate: (titleChunk) => {
    return titleChunk ? `${siteTitle} - ${titleChunk}` : siteTitle
  },
})

const { isNotProduction } = useRuntimeConfig().public

const { isActive } = useLoading()

// @see plugins/dayjs.client.ts
const i18n = useI18n()
watchEffect(() => locale(i18n.locale.value))
</script>

<template>
  <div>
    <article v-if="isNotProduction" class="message is-info m-0 env-notification">
      <span class="message-header">NOT PRODUCTION / SAFE TO USE</span>
    </article>
    <TheSnackbar />
    <div id="page">
      <NuxtLayout>
        <NuxtPage />
      </NuxtLayout>
    </div>
    <LoadingModal id="loading" :is-active="isActive" />

    <div id="preview"></div>
    <div id="dialog"></div>
  </div>
</template>

<style scoped lang="scss">
.env-notification {
  height: 24px;

  span {
    font-size: 0.6rem;
  }
}

#loading {
  position: fixed;
  z-index: 3;
}

#preview {
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
}

#dialog {
  position: absolute;
  z-index: 1;
}

#page {
  position: relative;
  z-index: 0;
}
</style>
