import { useNavigatorLanguage } from '@vueuse/core'
import { ref } from 'vue'

export type SupportedLocale = 'ja' | 'en' | 'th' | 'vi'

export const useLocale = () => {
  const { language } = useNavigatorLanguage()
  const currentLocale = ref(fallbackLocale(language.value))
  watch(language, (newValue) => {
    currentLocale.value = fallbackLocale(newValue)
  })
  return {
    currentLocale,
  }
}

const fallbackLocale = (locale: string | undefined): SupportedLocale => {
  if (locale?.startsWith('ja')) return 'ja'
  if (locale?.startsWith('en')) return 'en'
  if (locale?.startsWith('th')) return 'th'
  if (locale?.startsWith('vi')) return 'vi'
  return 'en'
}
