// FIXME: remove after nuxtjs/supabase installed
import type { AuthChangeEvent, Session } from '@supabase/supabase-js'

export default defineNuxtPlugin(async (nuxtApp) => {
  const supabaseUser = useSupabaseUser()
  const supabaseClient = useSupabaseClient()

  // Supabase server⇔ブラウザ間のsessionから復元を試行
  // middleware/auth.global.ts におけるログイン判定が後述の hooks よりも先に処理されてしまうため、プラグイン読み込み時点で一度復元を実施
  if (!supabaseUser.value) {
    const {
      data: { user },
      error,
    } = await supabaseClient.auth.getUser()
    if (!error) supabaseUser.value = user
  }

  // Once Nuxt app is mounted
  nuxtApp.hooks.hook('app:mounted', () => {
    // Listen to Supabase auth changes
    supabaseClient.auth.onAuthStateChange((_event: AuthChangeEvent, session: Session | null) => {
      if (session) {
        supabaseUser.value = session.user
      } else {
        // User must be unset before session
        supabaseUser.value = null
      }
    })
  })
})
