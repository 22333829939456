export const generatePseudoEmail = (accountName: string, organizationId: number): string => {
  if (!isValidAccountName(accountName)) {
    throw new Error(`Invalid account name: ${accountName}`)
  }
  return `${accountName}@${organizationId}.studist-account.jp`
}

export const isPseudoEmail = (email: string): boolean => {
  return email.endsWith('.studist-account.jp')
}

export const isValidAccountName = (name: string): boolean => {
  return /^[a-zA-Z0-9._%+-]+$/.test(name)
}

export const isValidEmail = (value: string) => {
  return /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(value)
}

export const extractAccountName = (email: string): string => {
  if (!isValidEmail(email)) {
    throw new Error('Invalid email address')
  }

  const atIndex = email.indexOf('@')
  if (atIndex === -1) {
    throw new Error('Invalid email address')
  }
  return email.slice(0, Math.min(atIndex, MAX_NAME_LENGTH * 2))
}
