import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'

// Dayjs の言語設定もインポートしておく、app.vue で VueI18n と同期する
import 'dayjs/locale/ja'
import 'dayjs/locale/en'
import 'dayjs/locale/th'
import 'dayjs/locale/vi'

// eslint-disable-next-line import/no-named-as-default-member
dayjs.extend(utc) // dayjsのutcプラグインを有効化

export default dayjs
