export default defineNuxtRouteMiddleware(async ({ name }) => {
  const supabaseUser = useSupabaseUser()
  if (!supabaseUser.value) return

  if (name === 'organizationId') return
  if (!name?.toString().match(/^organizationId-locations-locationId/)) return

  const { organization } = useOrganization()
  if (!organization.value) return

  const { getLocations } = useLocation()
  const locations = await getLocations() // TODO: キャッシュする
  if (locations.length > 0) return
  return await navigateTo({ name: 'organizationId', params: { organizationId: organization.value.id } })
})
