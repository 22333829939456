// FIXME: remove after nuxtjs/supabase installed
import { SupabaseClient } from '@supabase/supabase-js'
import type { Database } from '~/apis/generated/supabase'

export type Client = SupabaseClient<Database>

// TODO: Tは受け取らなくて良いので消す
export const useSupabaseClient = <T = Database>(): SupabaseClient<T> => {
  const nuxtApp = useNuxtApp()

  // No need to recreate client if exists
  if (!nuxtApp._supabaseClient) {
    nuxtApp._supabaseClient = createSupabaseClient()
  }

  return nuxtApp._supabaseClient as SupabaseClient<T>
}
