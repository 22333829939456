import {
  getLocations,
  getLocation,
  createLocation,
  updateLocation,
  deleteLocation,
} from '@/repositories/locationRepository'

export const useLocation = () => {
  return {
    getLocations,
    getLocation,
    createLocation,
    updateLocation,
    deleteLocation,
  }
}
