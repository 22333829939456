// Strategy to define useState in composables and share around the whole project
// https://nuxt.com/docs/getting-started/state-management#shared-state
import { deleteUserAccount } from '~/repositories/userAccountRepository'
import type { Database } from '~/apis/generated/supabase'
import { getOrganizationByAccountId } from '~/repositories/organizationRepository'

export type UpdateAccountParams = {
  accountId: string
  email?: string
  password?: string
  name?: string
  isPending?: boolean
}

export const useAuth = () => {
  const signIn = async (email: string, password: string): Promise<{ error: any }> => {
    const supabaseAuthClient = useSupabaseClient<Database>()

    if (isPseudoEmail(email)) {
      return { error: 'authentication failed' }
    } else if (!isValidEmail(email)) {
      return { error: 'invalid email format' }
    }

    // supabaseUser
    const {
      data: { user: supabaseUser },
      error: signInError,
    } = await supabaseAuthClient.auth.signInWithPassword({ email, password })
    if (signInError || !supabaseUser) return { error: signInError || 'authentication failed' }

    // stateを登録
    const { organization } = useOrganization()
    organization.value = await getOrganizationByAccountId(supabaseUser.id)
    const { currentUser, recoverUser } = useCurrentUser()
    await recoverUser()
    const { currentLocationId } = useCurrentLocation()

    if (!currentUser.value) throw new Error('user not found')
    currentLocationId.value = currentUser.value.location_id

    return { error: null }
  }

  const signOut = async () => {
    const supabaseAuthClient = useSupabaseClient<Database>()
    await supabaseAuthClient.auth.signOut()

    const { organization } = useOrganization()
    organization.value = null
    const { user } = useCurrentUser()
    user.value = null
  }

  const recheckSession = async () => {
    // localStorageのsessionは有効期間内でもDB上のsessionが消されている場合、利用できない機能（auth)があるため
    // ログイン画面に飛ばしたい
    // そのため、auth.getUser()を通じてsessionが有効かどうかを確認してsignOut(localStorageのトークンを削除)する
    const { error } = await useSupabaseClient().auth.getUser()
    if (error && error.status === 403) await signOut()
  }

  const inviteUserAccount = async (userId: number, email: string): Promise<{ error: any }> => {
    if (!isValidEmail(email) || isPseudoEmail(email)) return { error: 'invalid email' }

    const accountName = extractAccountName(email)

    const { organization } = useOrganization()
    const { error } = await useSupabaseClient<Database>().functions.invoke('invite-user-account', {
      body: {
        organizationId: organization.value!.id,
        userId,
        accountName,
        email,
      },
    })
    if (error) return { error }

    return { error: null }
  }

  const reinviteUserAccount = async (userId: number): Promise<{ error: any }> => {
    const { organization } = useOrganization()
    const { error } = await useSupabaseClient<Database>().functions.invoke('reinvite-user-account', {
      body: {
        organizationId: organization.value!.id,
        userId,
      },
    })
    if (error) return { error }

    return { error: null }
  }

  return {
    signIn,
    signOut,
    recheckSession,
    inviteUserAccount,
    reinviteUserAccount,
    deleteUserAccount,
  }
}
