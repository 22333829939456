export default defineNuxtRouteMiddleware(async ({ name }) => {
  const supabaseUser = useSupabaseUser()
  if (!supabaseUser.value) return

  if (!name) return
  if (name === 'organizationId') return
  if (name === 'organizationId-locations-locationId') return
  if (name !== 'index') return

  const { organization } = useOrganization()
  if (!organization.value) return

  const { currentLocationId } = useCurrentLocation()

  if (currentLocationId.value) {
    return await navigateTo({
      name: 'organizationId-locations-locationId-myPage',
      params: { organizationId: organization.value.id, locationId: currentLocationId.value },
    })
  } else {
    return await navigateTo({ name: 'organizationId', params: { organizationId: organization.value.id } })
  }
})
