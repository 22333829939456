import type { Database } from "~/apis/generated/supabase"
import type { Organization, OrganizationUpdateParams } from "~/entities/organization"

export const getOrganization = async (organizationId: number) => {
  const { data, error } = await useSupabaseClient<Database>()
    .from('organizations')
    .select()
    .eq('id', organizationId)
    .single()

  if (error) {
    throw error
  }

  return data
}

export const getOrganizationByAccountId = async (accountId: string) => {
  const { data, error } = await useSupabaseClient<Database>()
    .from('organizations')
    .select('*, user_accounts!inner(id)')
    .eq('user_accounts.id', accountId)
    .single()

  if (error) {
    throw error
  }

  const { user_accounts: _, ...organization } = data
  return organization as Organization
}

export const updateOrganization = async (
  id: number,
  params: OrganizationUpdateParams
) => {
  const { data, error } = await useSupabaseClient<Database>()
    .from('organizations')
    .update(params)
    .eq('id', id)
    .select()
    .single()
  if (error) {
    throw error
  }
  return data
}
