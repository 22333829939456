import type { Database } from '~/apis/generated/supabase'
import type { UserAccountCreateParams, UserAccountUpdateParams } from '~/entities/userAccount'

export const createUserAccount = async (params: UserAccountCreateParams) => {
  return await useSupabaseClient<Database>().from('user_accounts').insert(params).select().single()
}

export const getUserAccount = async (id: string) => {
  const { data, error } = await useSupabaseClient<Database>().from('user_accounts').select().eq('id', id).single()
  if (error) {
    throw error
  }
  return data
}

export const getUserAccountByUserId = async (id: number) => {
  const { data, error } = await useSupabaseClient<Database>().from('user_accounts').select().eq('user_id', id).single()
  if (error) throw error
  return data
}

export const getUserAccountByWorklineId = async (id: number) => {
  const { data, error } = await useSupabaseClient<Database>()
    .from('user_accounts')
    .select()
    .eq('workline_id', id)
    .single()
  if (error) throw error
  return data
}

export const isUserAccountAlreadyInUse = async (emails: string[]): Promise<boolean> => {
  const { data, error } = await useSupabaseClient().functions.invoke('get-duplicate-emails', {
    body: { emails },
  })
  if (error) throw error

  return data.duplicateEmails.length > 0
}

export const deleteUserAccount = async (accountId: string): Promise<void> => {
  const { organization } = useOrganization()
  if (!organization.value) throw new Error('Organization is not set')
  const { error } = await useSupabaseClient<Database>().functions.invoke('delete-user-account', {
    body: {
      organizationId: organization.value.id,
      accountId,
    },
  })
  if (error) throw error
}

export const updateUserAccount = async (accountId: string, params: UserAccountUpdateParams & { password?: string }) => {
  if (params.email || params.password) {
    await _updateUserAccountAuthInfo(accountId, { email: params.email, password: params.password })
  }

  const { password: _, ...rest } = params

  const values = Object.values(rest).filter((v) => v !== undefined)

  if (values.length === 0) {
    const { data, error } = await useSupabaseClient().from('user_accounts').select().eq('id', accountId).single()

    if (error) {
      throw error
    }

    return data
  }

  const { error, data } = await useSupabaseClient<Database>()
    .from('user_accounts')
    .update(rest)
    .eq('id', accountId)
    .select()
    .single()

  if (error) {
    throw error
  }

  return data
}

const _updateUserAccountAuthInfo = async (accountId: string, params: { email?: string; password?: string }) => {
  const supabase = useSupabaseClient<Database>()
  const { organization } = useOrganization()
  if (!organization.value) throw new Error('Organization is not set')
  const { error } = await supabase.functions.invoke('update-user-account', {
    body: {
      accountId,
      email: params.email,
      password: params.password,
      organizationId: organization.value.id,
    },
  })
  if (error) throw error
}
