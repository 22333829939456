import type { Database } from '~/apis/generated/supabase'
import type { SortOptions } from '~/entities/supabase'
import type { User, UserCreateParams, UserUpdateParams, UserWithAccountInfo } from '~/entities/user'

export const createUser = async (params: UserCreateParams): Promise<User> => {
  const { data, error } = await useSupabaseClient<Database>().from('users').insert(params).select().single()
  if (error) {
    throw error
  }
  return data
}

export const getUser = async (id: number) => {
  const { data, error } = await useSupabaseClient<Database>().from('users').select().eq('id', id).single()
  if (error) {
    throw error
  }
  return data
}

export const getUserByAccountId = async (accountId: string) => {
  const { data, error } = await useSupabaseClient<Database>()
    .from('users')
    .select('*, user_accounts!user_id!inner(id, is_pending)')
    .eq('user_accounts.id', accountId)
    .single()
  if (error) throw error
  return data
}

export const updateUser = async (id: number, params: UserUpdateParams) => {
  const { data, error } = await useSupabaseClient<Database>()
    .from('users')
    .update(params)
    .eq('id', id)
    .select()
    .single()
  if (error) {
    throw error
  }
  return data
}

export const deleteUser = async (id: number): Promise<void> => {
  const { error } = await useSupabaseClient().from('users').delete().eq('id', id)
  if (error) throw error
}

export const getUsersByLocationId = async ({ locationId }: { locationId: User['location_id'] }): Promise<User[]> => {
  if (!locationId) return []
  const query = useSupabaseClient<Database>().from('users').select().eq('location_id', locationId)
  const { data, error } = await query
  if (error) {
    throw error
  }
  return data
}

// 拠点に対してチェックファイルの変更権限がある人を全て取得
export const getAdminsByLocationId = async (organizationId: number, locationId: number) => {
  const ORGANIZATION_ADMIN: User['role'] = 'ORGANIZATION_ADMIN'
  const LOCATION_ADMIN: User['role'] = 'LOCATION_ADMIN'
  const WORK_ADMIN: User['role'] = 'WORK_ADMIN'
  const ROLE: keyof User = 'role'
  const LOCATION_ID: keyof User = 'location_id'
  const { data, error } = await useSupabaseClient()
    .from('users')
    .select('*,user_accounts!user_id!inner(id)') // 古い形式のユーザーを弾くためにuser_accountsをJOINしている
    .eq('organization_id', organizationId)
    .or(
      `${ROLE}.eq.${ORGANIZATION_ADMIN},and(${ROLE}.in.(${LOCATION_ADMIN},${WORK_ADMIN}),${LOCATION_ID}.eq.${locationId})`
    )
    .order('role')
    .order('name')

  if (error) {
    throw error
  }
  return data
}

export const getLocationUsersWithAccountInfo = async (
  locationId: number,
  sortOptions: SortOptions<User> = [
    { column: 'role', order: 'asc' },
    { column: 'name', order: 'asc' },
    { column: 'id', order: 'asc' },
  ]
): Promise<UserWithAccountInfo[]> => {
  const query = useSupabaseClient<Database>()
    .from('users')
    .select('*, user_accounts!user_id!inner(id, email, is_pending), locations!inner(id, name)')
    .eq('location_id', locationId)
    .neq('role', 'ORGANIZATION_ADMIN') // 拠点管理者のみ取得したいので、組織管理者は除外する
  for (const { column, order } of sortOptions) {
    query.order(column, { ascending: order === 'asc' })
  }
  const { data, error } = await query
  if (error) throw error
  return data.map((user) => ({
    ...user,
    accountId: user.user_accounts[0].id,
    email: user.user_accounts[0].email,
    isPending: user.user_accounts[0].is_pending,
    locationName: user.locations?.name ?? '-',
  }))
}

export const getUsersWithAccountInfo = async (
  sortOptions: SortOptions<User> = [
    { column: 'role', order: 'asc' },
    { column: 'location_id', order: 'asc' },
    { column: 'name', order: 'asc' },
    { column: 'id', order: 'asc' },
  ]
): Promise<UserWithAccountInfo[]> => {
  const query = useSupabaseClient<Database>()
    .from('users')
    .select('*, user_accounts!user_id!inner(id, email, is_pending), locations(id, name)')
  for (const { column, order } of sortOptions) {
    query.order(column, { ascending: order === 'asc' })
  }
  const { data, error } = await query
  if (error) throw error
  return data.map((user) => ({
    ...user,
    accountId: user.user_accounts[0].id,
    email: user.user_accounts[0].email,
    isPending: user.user_accounts[0].is_pending,
    locationName: user.locations?.name ?? '-',
  }))
}

export const getAdminsCount = async (role: User['role'], locationId: User['location_id']) => {
  const query = useSupabaseClient()
    .from('users')
    .select('id, user_accounts!user_id!inner(id)', { count: 'exact', head: true })
    .eq('role', role)
  if (role !== 'ORGANIZATION_ADMIN' && locationId) {
    query.eq('location_id', locationId)
  }
  const { count, error } = await query
  if (error) throw error
  return count ?? 0
}
