// FIXME: remove after nuxtjs/supabase installed
import { createClient } from '@supabase/supabase-js'
// eslint-disable-next-line import/no-named-as-default
import defu from 'defu'

export const createSupabaseClient = () => {
  const { supabase: { url, key, client: clientOptions } } = useRuntimeConfig().public

  // Set auth options to fix https://github.com/supabase/gotrue-js/issues/539
  const auth = {
    detectSessionInUrl: process.server ? false : clientOptions.auth?.detectSessionInUrl,
    persistSession: process.server ? false : clientOptions.auth?.persistSession,
    autoRefreshToken: process.server ? false : clientOptions.auth?.autoRefreshToken
  }

  // Set auth header
  const options = defu({ auth }, clientOptions)

  return createClient(url, key, options)
}
