import { default as edit6vIA4vqjk0Meta } from "/home/runner/work/checkup/checkup/admin/src/pages/[organizationId]/edit.vue?macro=true";
import { default as indexQvIHUqleLiMeta } from "/home/runner/work/checkup/checkup/admin/src/pages/[organizationId]/index.vue?macro=true";
import { default as indexNrB71OOuT6Meta } from "/home/runner/work/checkup/checkup/admin/src/pages/[organizationId]/locations/[locationId]/accounts/index.vue?macro=true";
import { default as indexeLmHSCxh3CMeta } from "/home/runner/work/checkup/checkup/admin/src/pages/[organizationId]/locations/[locationId]/ancillaryWorks/index.vue?macro=true";
import { default as indexTqvgusngYRMeta } from "/home/runner/work/checkup/checkup/admin/src/pages/[organizationId]/locations/[locationId]/checkFileLabels/index.vue?macro=true";
import { default as indexwTPx0c0FJ7Meta } from "/home/runner/work/checkup/checkup/admin/src/pages/[organizationId]/locations/[locationId]/checkFiles/[checkFileId]/index.vue?macro=true";
import { default as indexC1PAPQyttJMeta } from "/home/runner/work/checkup/checkup/admin/src/pages/[organizationId]/locations/[locationId]/checkFiles/index.vue?macro=true";
import { default as indexJqxCrKWELfMeta } from "/home/runner/work/checkup/checkup/admin/src/pages/[organizationId]/locations/[locationId]/checkSheetLabels/index.vue?macro=true";
import { default as index4DgUhJKpM6Meta } from "/home/runner/work/checkup/checkup/admin/src/pages/[organizationId]/locations/[locationId]/checkSheets/[checkSheetId]/index.vue?macro=true";
import { default as indexQbevPVZwdQMeta } from "/home/runner/work/checkup/checkup/admin/src/pages/[organizationId]/locations/[locationId]/checkSheets/index.vue?macro=true";
import { default as editv4mOX1hT33Meta } from "/home/runner/work/checkup/checkup/admin/src/pages/[organizationId]/locations/[locationId]/edit.vue?macro=true";
import { default as indexI4ODxwBtNzMeta } from "/home/runner/work/checkup/checkup/admin/src/pages/[organizationId]/locations/[locationId]/monthlyWorkFiles/[checkFileId]/[yyyy]/[mm]/index.vue?macro=true";
import { default as indexGGrfDxtRBmMeta } from "/home/runner/work/checkup/checkup/admin/src/pages/[organizationId]/locations/[locationId]/monthlyWorkFiles/index.vue?macro=true";
import { default as indexr0jXHZvt1lMeta } from "/home/runner/work/checkup/checkup/admin/src/pages/[organizationId]/locations/[locationId]/myPage/dailyVerifications/[workFileId]/index.vue?macro=true";
import { default as index1zCQHjywX6Meta } from "/home/runner/work/checkup/checkup/admin/src/pages/[organizationId]/locations/[locationId]/myPage/dailyVerifications/index.vue?macro=true";
import { default as indexqDKEmSkIiyMeta } from "/home/runner/work/checkup/checkup/admin/src/pages/[organizationId]/locations/[locationId]/myPage/index.vue?macro=true";
import { default as indexn1fJrCMdLMMeta } from "/home/runner/work/checkup/checkup/admin/src/pages/[organizationId]/locations/[locationId]/myPage/monthlyVerifications/[monthlyWorkFileId]/index.vue?macro=true";
import { default as indexWNj9qS2YqBMeta } from "/home/runner/work/checkup/checkup/admin/src/pages/[organizationId]/locations/[locationId]/myPage/monthlyVerifications/index.vue?macro=true";
import { default as indextWc57f1myoMeta } from "/home/runner/work/checkup/checkup/admin/src/pages/[organizationId]/locations/[locationId]/myPage/tickets/index.vue?macro=true";
import { default as indexGPHQE6su8HMeta } from "/home/runner/work/checkup/checkup/admin/src/pages/[organizationId]/locations/[locationId]/noteCategories/index.vue?macro=true";
import { default as indexCDnLIopexVMeta } from "/home/runner/work/checkup/checkup/admin/src/pages/[organizationId]/locations/[locationId]/tickets/[ticketId]/index.vue?macro=true";
import { default as index3MDBG7ENi4Meta } from "/home/runner/work/checkup/checkup/admin/src/pages/[organizationId]/locations/[locationId]/tickets/index.vue?macro=true";
import { default as index40k5VDnkbnMeta } from "/home/runner/work/checkup/checkup/admin/src/pages/[organizationId]/locations/[locationId]/timeboxes/index.vue?macro=true";
import { default as indexBcWeIJ8s9wMeta } from "/home/runner/work/checkup/checkup/admin/src/pages/[organizationId]/locations/[locationId]/users/index.vue?macro=true";
import { default as indexBHDYTJfqmyMeta } from "/home/runner/work/checkup/checkup/admin/src/pages/[organizationId]/locations/index.vue?macro=true";
import { default as editGHgFQjcO7XMeta } from "/home/runner/work/checkup/checkup/admin/src/pages/[organizationId]/myself/edit.vue?macro=true";
import { default as passwordEditUq0UUg4lmJMeta } from "/home/runner/work/checkup/checkup/admin/src/pages/[organizationId]/myself/passwordEdit.vue?macro=true";
import { default as setupl3Z5pSD6xXMeta } from "/home/runner/work/checkup/checkup/admin/src/pages/[organizationId]/myself/setup.vue?macro=true";
import { default as indexQzaDxmivP5Meta } from "/home/runner/work/checkup/checkup/admin/src/pages/[organizationId]/users/index.vue?macro=true";
import { default as indexNbf5aBQBcSMeta } from "/home/runner/work/checkup/checkup/admin/src/pages/images/[organizationId]/[objectId]/index.vue?macro=true";
import { default as indexyCRuzXtI5RMeta } from "/home/runner/work/checkup/checkup/admin/src/pages/index.vue?macro=true";
import { default as loginMJqt2tvkXPMeta } from "/home/runner/work/checkup/checkup/admin/src/pages/login.vue?macro=true";
export default [
  {
    name: "organizationId-edit",
    path: "/:organizationId()/edit",
    meta: edit6vIA4vqjk0Meta || {},
    component: () => import("/home/runner/work/checkup/checkup/admin/src/pages/[organizationId]/edit.vue").then(m => m.default || m)
  },
  {
    name: "organizationId",
    path: "/:organizationId()",
    meta: indexQvIHUqleLiMeta || {},
    component: () => import("/home/runner/work/checkup/checkup/admin/src/pages/[organizationId]/index.vue").then(m => m.default || m)
  },
  {
    name: "organizationId-locations-locationId-accounts",
    path: "/:organizationId()/locations/:locationId()/accounts",
    meta: indexNrB71OOuT6Meta || {},
    component: () => import("/home/runner/work/checkup/checkup/admin/src/pages/[organizationId]/locations/[locationId]/accounts/index.vue").then(m => m.default || m)
  },
  {
    name: "organizationId-locations-locationId-ancillaryWorks",
    path: "/:organizationId()/locations/:locationId()/ancillaryWorks",
    meta: indexeLmHSCxh3CMeta || {},
    component: () => import("/home/runner/work/checkup/checkup/admin/src/pages/[organizationId]/locations/[locationId]/ancillaryWorks/index.vue").then(m => m.default || m)
  },
  {
    name: "organizationId-locations-locationId-checkFileLabels",
    path: "/:organizationId()/locations/:locationId()/checkFileLabels",
    meta: indexTqvgusngYRMeta || {},
    component: () => import("/home/runner/work/checkup/checkup/admin/src/pages/[organizationId]/locations/[locationId]/checkFileLabels/index.vue").then(m => m.default || m)
  },
  {
    name: "organizationId-locations-locationId-checkFiles-checkFileId",
    path: "/:organizationId()/locations/:locationId()/checkFiles/:checkFileId()",
    meta: indexwTPx0c0FJ7Meta || {},
    component: () => import("/home/runner/work/checkup/checkup/admin/src/pages/[organizationId]/locations/[locationId]/checkFiles/[checkFileId]/index.vue").then(m => m.default || m)
  },
  {
    name: "organizationId-locations-locationId-checkFiles",
    path: "/:organizationId()/locations/:locationId()/checkFiles",
    meta: indexC1PAPQyttJMeta || {},
    component: () => import("/home/runner/work/checkup/checkup/admin/src/pages/[organizationId]/locations/[locationId]/checkFiles/index.vue").then(m => m.default || m)
  },
  {
    name: "organizationId-locations-locationId-checkSheetLabels",
    path: "/:organizationId()/locations/:locationId()/checkSheetLabels",
    meta: indexJqxCrKWELfMeta || {},
    component: () => import("/home/runner/work/checkup/checkup/admin/src/pages/[organizationId]/locations/[locationId]/checkSheetLabels/index.vue").then(m => m.default || m)
  },
  {
    name: "organizationId-locations-locationId-checkSheets-checkSheetId",
    path: "/:organizationId()/locations/:locationId()/checkSheets/:checkSheetId()",
    meta: index4DgUhJKpM6Meta || {},
    component: () => import("/home/runner/work/checkup/checkup/admin/src/pages/[organizationId]/locations/[locationId]/checkSheets/[checkSheetId]/index.vue").then(m => m.default || m)
  },
  {
    name: "organizationId-locations-locationId-checkSheets",
    path: "/:organizationId()/locations/:locationId()/checkSheets",
    meta: indexQbevPVZwdQMeta || {},
    component: () => import("/home/runner/work/checkup/checkup/admin/src/pages/[organizationId]/locations/[locationId]/checkSheets/index.vue").then(m => m.default || m)
  },
  {
    name: "organizationId-locations-locationId-edit",
    path: "/:organizationId()/locations/:locationId()/edit",
    meta: editv4mOX1hT33Meta || {},
    component: () => import("/home/runner/work/checkup/checkup/admin/src/pages/[organizationId]/locations/[locationId]/edit.vue").then(m => m.default || m)
  },
  {
    name: "organizationId-locations-locationId-monthlyWorkFiles-checkFileId-yyyy-mm",
    path: "/:organizationId()/locations/:locationId()/monthlyWorkFiles/:checkFileId()/:yyyy()/:mm()",
    meta: indexI4ODxwBtNzMeta || {},
    component: () => import("/home/runner/work/checkup/checkup/admin/src/pages/[organizationId]/locations/[locationId]/monthlyWorkFiles/[checkFileId]/[yyyy]/[mm]/index.vue").then(m => m.default || m)
  },
  {
    name: "organizationId-locations-locationId-monthlyWorkFiles",
    path: "/:organizationId()/locations/:locationId()/monthlyWorkFiles",
    meta: indexGGrfDxtRBmMeta || {},
    component: () => import("/home/runner/work/checkup/checkup/admin/src/pages/[organizationId]/locations/[locationId]/monthlyWorkFiles/index.vue").then(m => m.default || m)
  },
  {
    name: "organizationId-locations-locationId-myPage-dailyVerifications-workFileId",
    path: "/:organizationId()/locations/:locationId()/myPage/dailyVerifications/:workFileId()",
    meta: indexr0jXHZvt1lMeta || {},
    component: () => import("/home/runner/work/checkup/checkup/admin/src/pages/[organizationId]/locations/[locationId]/myPage/dailyVerifications/[workFileId]/index.vue").then(m => m.default || m)
  },
  {
    name: "organizationId-locations-locationId-myPage-dailyVerifications",
    path: "/:organizationId()/locations/:locationId()/myPage/dailyVerifications",
    meta: index1zCQHjywX6Meta || {},
    component: () => import("/home/runner/work/checkup/checkup/admin/src/pages/[organizationId]/locations/[locationId]/myPage/dailyVerifications/index.vue").then(m => m.default || m)
  },
  {
    name: "organizationId-locations-locationId-myPage",
    path: "/:organizationId()/locations/:locationId()/myPage",
    meta: indexqDKEmSkIiyMeta || {},
    component: () => import("/home/runner/work/checkup/checkup/admin/src/pages/[organizationId]/locations/[locationId]/myPage/index.vue").then(m => m.default || m)
  },
  {
    name: "organizationId-locations-locationId-myPage-monthlyVerifications-monthlyWorkFileId",
    path: "/:organizationId()/locations/:locationId()/myPage/monthlyVerifications/:monthlyWorkFileId()",
    meta: indexn1fJrCMdLMMeta || {},
    component: () => import("/home/runner/work/checkup/checkup/admin/src/pages/[organizationId]/locations/[locationId]/myPage/monthlyVerifications/[monthlyWorkFileId]/index.vue").then(m => m.default || m)
  },
  {
    name: "organizationId-locations-locationId-myPage-monthlyVerifications",
    path: "/:organizationId()/locations/:locationId()/myPage/monthlyVerifications",
    meta: indexWNj9qS2YqBMeta || {},
    component: () => import("/home/runner/work/checkup/checkup/admin/src/pages/[organizationId]/locations/[locationId]/myPage/monthlyVerifications/index.vue").then(m => m.default || m)
  },
  {
    name: "organizationId-locations-locationId-myPage-tickets",
    path: "/:organizationId()/locations/:locationId()/myPage/tickets",
    meta: indextWc57f1myoMeta || {},
    component: () => import("/home/runner/work/checkup/checkup/admin/src/pages/[organizationId]/locations/[locationId]/myPage/tickets/index.vue").then(m => m.default || m)
  },
  {
    name: "organizationId-locations-locationId-noteCategories",
    path: "/:organizationId()/locations/:locationId()/noteCategories",
    meta: indexGPHQE6su8HMeta || {},
    component: () => import("/home/runner/work/checkup/checkup/admin/src/pages/[organizationId]/locations/[locationId]/noteCategories/index.vue").then(m => m.default || m)
  },
  {
    name: "organizationId-locations-locationId-tickets-ticketId",
    path: "/:organizationId()/locations/:locationId()/tickets/:ticketId()",
    meta: indexCDnLIopexVMeta || {},
    component: () => import("/home/runner/work/checkup/checkup/admin/src/pages/[organizationId]/locations/[locationId]/tickets/[ticketId]/index.vue").then(m => m.default || m)
  },
  {
    name: "organizationId-locations-locationId-tickets",
    path: "/:organizationId()/locations/:locationId()/tickets",
    meta: index3MDBG7ENi4Meta || {},
    component: () => import("/home/runner/work/checkup/checkup/admin/src/pages/[organizationId]/locations/[locationId]/tickets/index.vue").then(m => m.default || m)
  },
  {
    name: "organizationId-locations-locationId-timeboxes",
    path: "/:organizationId()/locations/:locationId()/timeboxes",
    meta: index40k5VDnkbnMeta || {},
    component: () => import("/home/runner/work/checkup/checkup/admin/src/pages/[organizationId]/locations/[locationId]/timeboxes/index.vue").then(m => m.default || m)
  },
  {
    name: "organizationId-locations-locationId-users",
    path: "/:organizationId()/locations/:locationId()/users",
    meta: indexBcWeIJ8s9wMeta || {},
    component: () => import("/home/runner/work/checkup/checkup/admin/src/pages/[organizationId]/locations/[locationId]/users/index.vue").then(m => m.default || m)
  },
  {
    name: "organizationId-locations",
    path: "/:organizationId()/locations",
    meta: indexBHDYTJfqmyMeta || {},
    component: () => import("/home/runner/work/checkup/checkup/admin/src/pages/[organizationId]/locations/index.vue").then(m => m.default || m)
  },
  {
    name: "organizationId-myself-edit",
    path: "/:organizationId()/myself/edit",
    meta: editGHgFQjcO7XMeta || {},
    component: () => import("/home/runner/work/checkup/checkup/admin/src/pages/[organizationId]/myself/edit.vue").then(m => m.default || m)
  },
  {
    name: "organizationId-myself-passwordEdit",
    path: "/:organizationId()/myself/passwordEdit",
    meta: passwordEditUq0UUg4lmJMeta || {},
    component: () => import("/home/runner/work/checkup/checkup/admin/src/pages/[organizationId]/myself/passwordEdit.vue").then(m => m.default || m)
  },
  {
    name: "organizationId-myself-setup",
    path: "/:organizationId()/myself/setup",
    meta: setupl3Z5pSD6xXMeta || {},
    component: () => import("/home/runner/work/checkup/checkup/admin/src/pages/[organizationId]/myself/setup.vue").then(m => m.default || m)
  },
  {
    name: "organizationId-users",
    path: "/:organizationId()/users",
    meta: indexQzaDxmivP5Meta || {},
    component: () => import("/home/runner/work/checkup/checkup/admin/src/pages/[organizationId]/users/index.vue").then(m => m.default || m)
  },
  {
    name: "images-organizationId-objectId",
    path: "/images/:organizationId()/:objectId()",
    meta: indexNbf5aBQBcSMeta || {},
    component: () => import("/home/runner/work/checkup/checkup/admin/src/pages/images/[organizationId]/[objectId]/index.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    component: () => import("/home/runner/work/checkup/checkup/admin/src/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "login",
    path: "/login",
    meta: loginMJqt2tvkXPMeta || {},
    component: () => import("/home/runner/work/checkup/checkup/admin/src/pages/login.vue").then(m => m.default || m)
  }
]