export const isHiragana = (str: string) => {
  return str.match(/^[ぁ-んー]*$/)
}

// 半角換算で文字列の長さを取得する
export const get2byteLength = (str: string): number => {
    let count = 0;
    for (let i = 0; i < str.length; i++) {
      const charCode = str.charCodeAt(i);
        // https://www.ssec.wisc.edu/~tomw/java/unicode.html
        if ((charCode >= 0x3000 && charCode <= 0x9FFF) || // ひらがな、カタカナ、漢字など
            (charCode >= 0xFF01 && charCode <= 0xFF60) || // 全角記号、数字、アルファベット
            (charCode >= 0xFFE0 && charCode <= 0xFFE6)) { // 全角円マークなど
            count += 2;
        } else {
            // 半角文字の場合
            count += 1;
        }
    }
    return count;
}
