export type Toast = {
  message: string
  color: 'danger' | 'warning' | 'success' | 'none'
}

export const useToast = () => {
  const toasts = useState<Toast[]>(() => [])
  const hasToast = computed(() => toasts.value.length > 0)

  return {
    addToast: (toast: Toast) => {
      // pushでは変更検知されない
      toasts.value = [...toasts.value, toast]
    },
    popToast: () => {
      return toasts.value.pop()
    },
    hasToast,
  }
}
