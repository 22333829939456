export default defineNuxtRouteMiddleware(async (to, _) => {
  const routeName = to.name?.toString()

  // routeNameが無い場合middleware到達前に404ページが表示されるのでrouteNameは存在するはず
  if (routeName?.match(/^organizationId-locations-locationId/)) {
    const locationId = Number(to.params.locationId)
    // 0及びstring等の場合はlocationIdがNaNになる
    if (!locationId) {
      // auth middlewareを通ってるのでorganizationId-location-locationId-indexに飛ばせるがログイン画面に飛ばして
      // /loginにリダイレクトロジックを集約する
      return await navigateTo({ name: 'login' })
    }

    const { currentUser } = useCurrentUser()
    if (!currentUser.value) return

    if (currentUser.value.role !== 'ORGANIZATION_ADMIN' && currentUser.value.location_id !== locationId) {
      // 組織管理者以外は自分の所属する拠点以外にアクセスできないようにする
      return await navigateTo({
        name: to.name?.toString(),
        params: { ...to.params, locationId: currentUser.value.location_id },
      })
    }

    const { currentLocationId } = useCurrentLocation()
    if (currentLocationId.value !== locationId) {
      currentLocationId.value = locationId
    }
  }
})
