export default defineNuxtRouteMiddleware(async (to, _) => {
  const routeName = to.name?.toString()

  // routeNameが無い場合middleware到達前に404ページが表示されるのでrouteNameは存在するはず
  if (routeName?.match(/^organizationId/)) {
    const organizationId = Number(to.params.organizationId)
    // 0及びstring等の場合はorganizationIdがNaNになる
    if (!organizationId) {
      // organizationIdが不正な場合はindexから遷移し直し
      return await navigateTo({ name: 'index' })
    }

    // auth middlewareを通っているのでorganizationは存在するはず
    const { organization } = useOrganization()

    // 別組織を参照しようとした場合, indexから遷移し直し
    if (organization.value && organization.value.id !== organizationId) {
      return await navigateTo({ name: 'index' })
    }

    if (routeName !== 'organizationId') return

    // organizationIdへの遷移は拠点の有無によって遷移先を変える

    const { getLocations } = useLocation()
    const locations = await getLocations() // TODO: キャッシュする

    // 拠点がなければそのまま遷移させる
    if (locations.length === 0) return

    // 拠点がある場合はindexから遷移し直してマイページ等を表示する
    return await navigateTo({ name: 'index' })
  }
})
